import React from 'react';
import styled, {css} from "styled-components"

const ContainerStyled = styled.div`
  max-width: 1270px;
  padding: 0 30px;
  margin: 0 auto;

  @media (max-width: 468px) {
    padding: 0 20px;
  }
  
  ${props => props.top100 && css`
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: 768px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  `}

  
  
`

const Container = ({children, ...rest}) => {
    return (
        <ContainerStyled {...rest}>
            {children}
        </ContainerStyled>
    );
};

export default Container;