import React from 'react';
import styled from "styled-components"
import Container from "./ui/Container";
import phone from "./../img/contacts/phone.svg"
import eMail from "./../img/contacts/e-mail.svg"
import address from "./../img/contacts/address.svg"
import ethernet from "./../img/contacts/ethernet.svg"
import {useTranslation} from "react-i18next";


const ContactsStyled = styled.div`
    > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      > a {
        text-align: center;
        padding: 45px 30px;
        cursor: pointer;
        text-decoration: none;

        > img {
          transition: all .3s ease;
        }
        
        > h3 {
          font-size: 20px;
          font-weight: 500;
          margin-top: 20px;
          text-decoration: none;

        }
        
        > p {
          margin-top: 10px;
          text-decoration: none;
        }
        
        
        &:hover {
          background: #191919;
          > img {
            transform: scale(1.2);
            transition: all .3s ease;
          }
        }
      }
      
      @media(max-width: 1044px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media(max-width: 568px) {
        grid-template-columns: 1fr;
      }
      
    }
`





const Contacts = () => {
    const {t} = useTranslation("common")

    const contacts = [
        {id: 1, img: phone, href: "tel:900303030", title: t("contacts.0.title"), desc: t("contacts.0.desc")},
        {id: 2, img: eMail, href: "mailto:didor@makolli.tj", title: t("contacts.1.title"), desc: t("contacts.1.desc")},
        {id: 3, img: address, href: "#", title: t("contacts.2.title"), desc: t("contacts.2.desc")},
        {id: 4, img: ethernet, href: "https://makolli.tj/", title: t("contacts.3.title"), desc: t("contacts.3.desc")},
    ]

    return (
        <ContactsStyled>
            <Container top100 data-aos="fade-up">
                {contacts.map(el => <a href={el.href}>
                    <img src={el.img} alt=""/>
                    <h3>{el.title}</h3>
                    <p className="text__secondary">{el.desc}</p>
                </a>)}
            </Container>
        </ContactsStyled>
    );
};

export default Contacts;