import React, {useState} from 'react';
import styled from "styled-components";
import Container from "./ui/Container";
import logo from "./../img/logo.png"
import headerBG from "./../img/headerBG.webp"
import "/node_modules/hamburgers/dist/hamburgers.css";
import {useTranslation} from "react-i18next";

const HeaderStyled = styled.header`
  background: url(${headerBG}) no-repeat center bottom / cover;

  > div {


    > nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;

      > img {
        height: 50px;

        @media (max-width: 568px) {
          height: 45px;
        }

        @media (max-width: 468px) {
          height: 35px;
        }
      }

      > div {
        display: flex;
        align-items: center;
        column-gap: 60px;

        > ul {
          display: flex;
          align-items: center;
          column-gap: 40px;
          
          > li {
            cursor: pointer;
            &:hover {
              color: #D3AD7F;
            }
          }
          
          @media (max-width: 1000px) {
            display: none;
          }
        }

        > div {
          display: flex;
          align-items: center;
          column-gap: 30px;

          > button {
            @media (max-width: 1000px) {
              display: none;
            }
          }

          > p {
            cursor: pointer;
            @media (max-width: 1000px) {
              display: none;
            }
          }

          > div {
            display: none;

            > button {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              background: none;
              outline: none;
              border: none;

              > span {
                > span {
                  background: #D3AD7F;

                  &:before {
                    background: #D3AD7F;
                  }

                  &:after {
                    background: #D3AD7F;
                  }
                }
              }


              @media (max-width: 468px) {
                padding: 8px;
                > span {
                  width: 35px;
                }

                .hamburger-inner {
                  width: 35px;

                  &:after {
                    width: 35px;
                  }

                  &:before {
                    width: 35px;
                  }
                }
              }
            }

            > ul {
              position: absolute;
              background: #D3AD7F;
              padding: 15px 20px;
              right: 30px;
              margin-top: 15px;
              border-radius: 20px;
              min-width: 150px;
              z-index: 1;

              > li {
                color: #241C1E;
                line-height: 30px;
                font-weight: 500;
              }
            }

            @media (max-width: 1000px) {
              display: block;
            }
          }
        }
      }
    }

    > div {
      margin-top: 150px;
      max-width: 540px;
      padding-bottom: 150px;


      > h1 {
        font-size: 100px;
        text-transform: uppercase;
        font-weight: 700;
      }

      > p {
        margin-top: 40px;
        color: #938E8E;
        font-size: 20px;
        line-height: 30px;
      }

      > div {
        margin-top: 40px;

        > div {
          display: flex;
          align-items: center;
          column-gap: 30px;

          > button {
            text-transform: uppercase;
          }
        }
      }

      @media (max-width: 1000px) {
        padding-top: 100px;
        padding-bottom: 130px;
        margin: 0 auto;
        > h1 {
          text-align: center;
        }

        > p {
          text-align: center;
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      @media (max-width: 768px) {
        padding-top: 60px;
        padding-bottom: 0;
        > h1 {
          font-size: 80px;
        }
      }

      @media (max-width: 668px) {
        > h1 {
          font-size: 65px;
        }
      }

      @media (max-width: 568px) {
        > div {
          > div {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 10px;
          }
        }
      }

      @media (max-width: 468px) {
        > h1 {
          font-size: 55px;
        }
      }

    }
  }

`

const Header = ({scrollTo}) => {
    const [isToggle, setIsToggle] = useState(false)
    const {t, i18n} = useTranslation("common")


    return (
        <HeaderStyled>
            <Container>
                <nav>
                    <img src={logo} alt=""/>
                    <div>
                        <ul>
                            <li onClick={() => scrollTo("about")}>{t("nav.0")}</li>
                            <li onClick={() => scrollTo("product")}>{t("nav.1")}</li>
                            <li onClick={() => scrollTo("contacts")}>{t("nav.2")}</li>
                        </ul>
                        <div>
                            <button onClick={() => scrollTo("footer")} className="btn__primary">{t("nav.3")}</button>
                            <p onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}>{i18n.language === 'ru' ? 'EN' : 'RU'}</p>
                            <div>
                                <button
                                    onClick={() => setIsToggle(prev => !prev)}
                                    className={`menuBurgerLabel hamburger--3dy ${isToggle ? "is-active" : ''}`}
                                    type="button"
                                >
                                      <span className="hamburger-box">
                                        <span className="hamburger-inner"/>
                                      </span>
                                </button>
                                {isToggle &&
                                    <ul>
                                        <li onClick={() => scrollTo("about")}>{t("nav.0")}</li>
                                        <li onClick={() => scrollTo("product")}>{t("nav.1")}</li>
                                        <li onClick={() => scrollTo("contacts")}>{t("nav.2")}</li>
                                        <li>{t("nav.3")}</li>
                                        <li onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}>{i18n.language === 'ru' ? 'EN' : 'RU'}</li>
                                    </ul>}
                            </div>
                        </div>
                    </div>
                </nav>
                <div>
                    <h1 data-aos="fade-right">
                        {t("banner.0")} <br/>
                        {t("banner.1")}
                    </h1>
                    <p data-aos="fade-up">{t("banner.2")}</p>
                    <div data-aos="zoom-in">
                        <div>
                            <button onClick={() => scrollTo("footer")} className="btn__primary">{t("banner.3")}</button>
                            <button onClick={() => scrollTo("about")} className="btn__secondary">{t("banner.4")}</button>
                        </div>
                    </div>
                </div>
            </Container>
        </HeaderStyled>
    );
};

export default Header;