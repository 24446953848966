import React from 'react';
import styled from "styled-components"
import Container from "./ui/Container";
import coffee from "./../img/aboutCoffe.png"
import {useTranslation} from "react-i18next";

const AboutStyled = styled.div`
    > div {
      > div {
        max-width: 930px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        column-gap: 90px;
        
        > h1 {
          font-size: 26px;
          font-weight: 700;
        }
        
        @media(max-width: 1049px) {
          max-width: 800px;
          column-gap: 30px;
        }

        @media(max-width: 768px) {
          flex-wrap: wrap;
          row-gap: 40px;
          justify-content: center;
          text-align: center;
          max-width: 800px;
          column-gap: 30px;
        }
      }
    }
`


const About = () => {
    const {t} = useTranslation("common")

    return (
        <AboutStyled>
            <Container top100>
                <div>
                    <img data-aos="zoom-in" src={coffee} alt=""/>
                    <h1 data-aos="fade-right">{t("about.0")}</h1>
                    <p data-aos="fade-right" className="text__secondary">{t("about.1")}</p>
                </div>
            </Container>
        </AboutStyled>
    );
};

export default About;