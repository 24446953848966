import React from 'react';
import styled from "styled-components";

const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    column-gap: 15px;    
    
    > span {
      display: block;
      width: 100%;
      height: 1px;
      background: #fff;
      border-radius: 2px;
    }
    
    > h1 {
      font-size: 40px;
      text-align: center;
      text-transform: uppercase;
    }
  
    @media(max-width: 568px) {
      > h1 {
        font-size: 26px;
      }
    }
  
  }
  

`

const Title = ({title}) => {
    return (
        <TitleStyled>
            <div>
                <span/>
                <h1>{title}</h1>
                <span/>
            </div>
        </TitleStyled>
    );
};

export default Title;