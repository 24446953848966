import React from 'react';
import styled from "styled-components";
import Container from "./ui/Container";
import footerBg from "./../img/footer.webp"
import logo from "./../img/logo.png"
import img1 from "./../img/footer/iconoir_telegram-circled.svg"
import img2 from "./../img/footer/Frame 56.svg"
import img3 from "./../img/footer/facebook.svg"
import {useTranslation} from "react-i18next";

const FooterStyled = styled.footer`
  background: url(${footerBg}) no-repeat center center / cover;

  > div {
    display: flex;
    justify-content: space-between;

    > div {
      max-width: 360px;

      > img {
        height: 50px;
      }

      > p {
        margin-top: 30px;
      }

      > span {
        display: flex;
        align-items: center;
        margin-top: 40px;
        grid-gap: 36px;
      }

    }

    > span {
      display: flex;
      column-gap: 50px;

      > div, > form {
        > h3 {
          font-size: 20px;
          font-weight: 500;
          padding-bottom: 20px;
        }
      }

      > div {
        > a {
          display: block;
          margin-top: 10px;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            color: rgba(248, 248, 248, 0.7);
          }
        }
      }

      > form {
        > input {
          display: block;
          padding: 13px 22px;
          border-radius: 10px;
          outline: none;
          background: #fff;
          border: none;
          font-size: 14px;
          width: 250px;
          color: #241C1E;
          margin-top: 12px;
        }

        > button {
          margin-top: 30px;
        }

      }
    }

    @media (max-width: 1211px) {
      display: block;

      > div {
        text-align: center;
        max-width: 600px;
        margin: 0 auto;

        > span {
          justify-content: space-around;
          padding: 0 60px;
        }
      }

      > span {
        margin-top: 60px;
        justify-content: space-around;

        @media (max-width: 846px) {
          display: block;
          text-align: center;
          > div {
            margin-top: 40px;
          }

          > form {
            margin-top: 40px;

            > input {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
`


const Footer = () => {
    const {t} = useTranslation("common")


    return (
        <FooterStyled>
            <Container top100 data-aos="fade-up">
                <div>
                    <img src={logo} alt=""/>
                    <p className="text__secondary">{t("footer.title")}</p>
                    <span>
                        <img src={img1} alt=""/>
                        <img src={img2} alt=""/>
                        <img src={img3} alt=""/>
                    </span>
                </div>
                <span>
                    <div>
                        <h3>{t("footer.data.0.title")}</h3>
                        <a href="https://makolli.tj/strategy/" className="text__secondary">{t("footer.data.0.data.0")}</a>
                        <a href="https://makolli.tj/strategy/" className="text__secondary">{t("footer.data.0.data.1")}</a>
                    </div>
                     <div>
                        <h3>{t("footer.data.1.title")}</h3>
                        <a href="http://bread.makolli.tj/" className="text__secondary">{t("footer.data.1.data.0")}</a>
                        <a href="http://pasta.makolli.tj/" className="text__secondary">{t("footer.data.1.data.1")}</a>
                        <a href="http://nostalgiya.makolli.tj/" className="text__secondary">{t("footer.data.1.data.2")}</a>
                        <a href="http://tm.makolli.tj/" className="text__secondary">{t("footer.data.1.data.3")}</a>
                    </div>
                     <form>
                        <h3>{t("footer.data.2.title")}</h3>
                        <input type="text" placeholder={t("footer.data.2.data.0")}/>
                        <button className="btn__secondary">{t("footer.data.2.data.1")}</button>
                    </form>
                </span>
            </Container>
            <p className={"text__secondary"} style={{textAlign: "center", fontSize: "14px", paddingBottom: "30px"}}>{t("footer.bottomFooter")}</p>
        </FooterStyled>
    );
};

export default Footer;