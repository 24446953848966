import React, {useState, useEffect} from 'react';
import Header from "./components/Header";
import About from "./components/About";
import Product from "./components/Product";
import bannerCoffee from "./img/coffeeBanner.png";
import Coffee from "./components/Coffee";
import Advantage from "./components/Advantage";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
import styled from "styled-components"
import {scroller, Element} from "react-scroll";
import LoaderApp from "./components/ui/LoaderApp";
import 'aos/dist/aos.css';
import AOS from 'aos';



const ImgStyled = styled.div`
  background: linear-gradient(0, #191919 57%, transparent 57%);

  > img {
    display: block;
    margin: 0 auto;

    @media (max-width: 1315px) {
      width: 100%;
    }
  }
`

const App = () => {
    const [loaderApp, setLoaderApp] = useState(true)

    const scrollTo = (name) => {
        scroller.scrollTo(name, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    useEffect(() => {
        AOS.init({
            duration : 1500,
            once: true
        })
    }, [])

    useEffect(() => {
        setTimeout(() => setLoaderApp(false), 1500)
    }, [])

    return (
        <>
            {loaderApp
                ? <LoaderApp/>
                : <div style={{boxSizing: "border-box"}}>
                    <Header scrollTo={scrollTo}/>
                    <Element name="about">
                        <About/>
                    </Element>
                    <ImgStyled>
                        <img data-aos="zoom-in"  src={bannerCoffee} alt=""/>
                    </ImgStyled>
                    <Element name="product">
                        <Product/>
                    </Element>
                    {/*<Element name="coffee">*/}
                    {/*    <Coffee/>*/}
                    {/*</Element>*/}
                    <Advantage/>
                    <Element name="contacts">
                        <Contacts/>
                    </Element>
                    <Element name="footer">
                        <Footer/>
                    </Element>
                </div>}
        </>

    );
};

export default App;