import React from 'react';
import styled from "styled-components"
import Container from "./ui/Container";
import Title from "./ui/Title";
import img1 from "./../img/products/1.png"
import img2 from "./../img/products/2.png"
import img3 from "./../img/products/3.png"
import img4 from "./../img/products/4.png"
import {useTranslation} from "react-i18next";

const ProductStyled = styled.div`
  background: #191919;
  
  > div {
    > span {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;
      
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #605D5D;

        > div {
          display: flex;
          column-gap: 26px;
          
          > span {
            margin-top: 8px;
            > h3 {
              color: #D3AD7F;
              font-size: 22px;
            }
            
            > p {
              margin-top: 8px;
            }
          }
        }

        > p {
          color: #fff;
          font-size: 20px;
          font-weight: 700;
        }
      }
      
      @media(max-width: 944px) {
        grid-template-columns: 1fr;
      }

      @media(max-width: 568px) {
        grid-gap: 30px;
        > div {
          display: block;
          text-align: center;
          padding-bottom: 30px;
          
          > div {
            display: block;
          }
          
          > p {
            margin-top: 20px;
          }
          
        }
        grid-template-columns: 1fr;
      }
    }
  }

`




const Product = () => {
    const {t} = useTranslation("common")

    const products = [
        {id: 1, img: img1, title: t("products.data.0.title"), desc: t("products.data.0.desc"), price: t("products.data.0.price")},
        {id: 2, img: img2, title: t("products.data.1.title"), desc: t("products.data.1.desc"), price: t("products.data.1.price")},
        {id: 3, img: img3, title: t("products.data.2.title"), desc: t("products.data.2.desc"), price: t("products.data.2.price")},
        {id: 4, img: img4, title: t("products.data.3.title"), desc: t("products.data.3.desc"), price: t("products.data.3.price")}
    ]

    return (
        <ProductStyled>
            <Container top100 data-aos="fade-up">
                <Title title={t("products.title")} />
                <span>
                    {products.map(el => <div key={el.id}>
                        <div>
                            <img src={el.img} alt=""/>
                            <span>
                                <h3>{el.title}</h3>
                                <p className="text__secondary">{el.desc}</p>
                            </span>
                        </div>
                        <p/>
                    </div>)}
                </span>
            </Container>
        </ProductStyled>
    );
};

export default Product;