import React from 'react';
import styled from "styled-components";
import Container from "./ui/Container";
import Title from "./ui/Title";
import img1 from "./../img/coffee/1.png"
import img2 from "./../img/coffee/2.png"
import {useTranslation} from "react-i18next";


const CoffeeStyled = styled.div`
  > div {
    > span {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      > div {
        padding: 38px 50px;
        text-align: center;
        
        > img {
          width: 100%;
        }
        
        
        > p {
          margin-top: 25px;

          @media(max-width: 568px) {
            margin-top: 20px;
          }
        }
        
        &:hover {
          background: #191919;
        }
      }
      
      @media(max-width: 871px) {
        grid-template-columns: 1fr 1fr;
      }

      @media(max-width: 668px) {
        grid-template-columns: 1fr;
      }
      
    }
  }

`



const Coffee = () => {
    const {t} = useTranslation("common")

    const coffees = [
        {id: 1, img: img1, title: t("coffee.data.0.title"), desc: t("coffee.data.0.desc")},
        {id: 2, img: img2, title: t("coffee.data.1.title"), desc: t("coffee.data.0.desc")},
        {id: 3, img: img1, title: t("coffee.data.2.title"), desc: t("coffee.data.0.desc")}
    ]

    return (
        <CoffeeStyled>
            <Container top100 data-aos="fade-up">
                <Title title={t("coffee.title")}/>
                <span>
                    {coffees.map(el => <div>
                        <img src={el.img} alt=""/>
                        <h3 className="text__primary__22">{el.title}</h3>
                        <p className="text__secondary">{el.desc}</p>
                    </div>)}
                </span>
            </Container>
        </CoffeeStyled>
    );
};

export default Coffee;