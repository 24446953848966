import React from 'react'
import imgLoaderApp from "./../../img/logo.png"
import styled from "styled-components";

const LoaderAppStyled = styled.div`
  width: 100%;
  height: 100%;
  background: #101011;
  position: fixed;
  top: 0;
  z-index: 99999;
  transition: 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    animation: pulse 2s infinite;
    height: 50px;

    @media (max-width: 568px) {
      height: 45px;
    }

    @media (max-width: 468px) {
      height: 35px;
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
    }
  }
`

function LoaderApp() {
    return <LoaderAppStyled>
        <img src={imgLoaderApp} alt=""/>
    </LoaderAppStyled>
}

export default LoaderApp