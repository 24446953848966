import React from 'react';
import styled from "styled-components";
import Container from "./ui/Container";
import img1 from "./../img/advantage/1.png"
import img1En from "./../img/advantage/1en.png"
import img2 from "./../img/advantage/2.png"
import img2En from "./../img/advantage/2en.png"
import img3 from "./../img/advantage/3.png"
import img3En from "./../img/advantage/3en.png"
import img4 from "./../img/advantage/4.png"
import img5 from "./../img/advantage/5.png"
import img6 from "./../img/advantage/6.png"
import img7 from "./../img/advantage/7.png"
import img7En from "./../img/advantage/7en.png"
import {useTranslation} from "react-i18next";

const AdvantageStyled = styled.div`
  > div {
    padding-left: 0;
    padding-right: 0;
    
    > span {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      > img {
        width: 100%;
      }

      @media (max-width: 568px) {
        grid-template-columns: 1fr;
      }
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      > img {
        width: 100%;
      }

      > div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        > img {
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 568px) {
        grid-template-columns: 1fr;
        > div {
          grid-template-columns: 1fr;

          > img {
            &:nth-child(3) {
              grid-row: 4;
            }
          }
        }
      }
    }
  }

`

const Advantage = () => {
    const {i18n} = useTranslation("common")


    return (
        <AdvantageStyled>
            <Container>
                <span>
                     <img src={img6} alt=""/>
                     <img src={i18n.language !== 'ru' ? img7En : img7} alt=""/>
                </span>
                <div>
                    <img src={i18n.language !== 'ru' ? img1En : img1} alt=""/>
                    <div>
                        <img src={i18n.language !== 'ru' ? img2En : img2} alt=""/>
                        <img src={img4} alt=""/>
                        <img src={img5} alt=""/>
                        <img src={i18n.language !== 'ru' ? img3En : img3} alt=""/>
                    </div>
                </div>
            </Container>
        </AdvantageStyled>
    );
};

export default Advantage;